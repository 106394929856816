export enum InteractionEvent {
  eventName = "interaction",
  deleteCartItem = "delete_cart_item"
}
export enum InteractionEventTypes {
  exitPopup = "exit_pop_up",
  deliverFeedback = "shipment_feedback",
  review = "review",
  reviewTags = "review_tags",
  helpful = "helpful",
  latestWidget = "Latest_widget",
  shareIcon = "share_icon",
  blogLink = "blog_link",
  removeFreebie = "remove",
  select = "select",
  crossFreebie = "Cross_freebie",
  doneFreebie = "Done_freebie",
  productGallery = "product_gallery",
  login = "login",
  signup = "signup",
  cartIcon = "cart_icon",
  back = "back",
  upsellOffer = 'Upsell_offer',
  offerStrip = 'Offer_strip',
  beautyQuiz = "beauty_quiz",
  downloadApp = "download_app",
  filterClick = "filter_click",
  priceRevealProductClick = "price_reveal_product_click",
  beautyBoxProductClick = "beauty_box_product_click",
  addMoreItems = "add_more_items",
  goToBeautybox = "go_to_beautybox",
  beautyBoxClick = "beauty_box_click",
  beautyBoxRemove = "beauty_box_remove",
  infoSave = "info_save",
  shopHome = "shop_home",
  proceedToPay = "Proceed_to_pay",
  Widget = 'Widget',
  notifyMe = 'notify',
  setEvent = "setEvent",
  videoClicked = "video_clicked",
  webpushPermission = "webpush_permission",
  webpushImpression = "webpush_impression",
  pwaPopup = "pwa_popup",
  viewAll = "view_all",
  contactUs = "Contact Us",
  support = "support",
  submitForm = "Submit Form",
  productPageType = "product_page",
  reviews = "reviews",
  readAll = "read_all",
  offers = "offers",
  share = "share",
  shippingInfo = "shipping_information",
  productPage = "Product Page",
  expand = "expand",
  ticketHistory = "Ticket History",
  viewTicket = "View Ticket",
  downloadAppPopup = "Download App",
  updateApp = "Update App",
  close = "Close",
  shippingAndOtherCharges = "shipping_and_other_charges",
  shippingCharge = "shipping_charge",
  smallCartCharge = "small_cart_charge",
  deleteReason = "delete reason",
  deleteAccount = "delete account",
  deleteConfirmed = "delete confirmed"
}
export enum InteractionEventSubTypes {
  cross = "cross",
  getCode = "get_code",
  enterNumber = "enter_number",
  submit = "submit",
  rating = "rating",
  modeExit = "mode_exit",
  exit = "exit",
  uploadRemove = "upload_remove",
  addAReview = "add_a_review",
  reviewTitle = "review_title",
  reviewText = "review_text",
  uploadMode = "upload_mode",
  viewAll = "view_all",
  reviewImage = "review_image",
  freebiePage = "Freebie_page",
  watchTime = "watch_time",
  back = "back",
  next = "next",
  select = "select",
  deselect = "deselect",
  video = "video",
  image = "image",
  truecallerOtherNumber = "truecaller-other-number",
  truecallerContinue = "truecaller-continue",
  phoneVerificationAmpt = "phn-verification-ampt",
  phoneRegisterAmpt = "phn-reg-ampt",
  cartIcon = "cart_icon",
  addItem = 'Add_item',
  offerViewAll = 'view all',
  logout = "logout",
  loginEmail = "login_email",
  loginPhone = "login_phone",
  registrationPhone = "registration_phone",
  registrationEmail = "registration_email",
  facebookLogin = "fblogin",
  facebookRegistration = "fbregistration",
  googleLogin = "glogin",
  googleRegistration = "gregistration",
  passwordSubmit = "password_submit",
  close = "close",
  infoSave = "info_save",
  shopHome = "shop_home",
  beautyQuiz = "beauty_quiz",
  checkoutAttempt = "checkout_attempt",
  goToCart = 'Go_to_Cart',
  product = "product",
  shades = "shades",
  popup = "popup",
  crossButton = "cross_button",
  addToHomeScreen = "add_to_home_screen",
  alreadyHaveApp = "already_have_app",
  chatWithUs = "Chat with Us",
  writeToUs = "Write to Us",
  callUs = "Call Us",
  submitForm = "Submit Form",
  checkDelivery = "check_delivery",
  viewCart = "VIEW CART",
  reviews = "reviews",
  offersTnC = "Offers T&C",
  sellers = "sellers",
  reviewRating = "review_rating",
  sellerInfoDialog = "seller_info_dialog",
  variantFilter = "variant_filter",
  productInfo = "product_info",
  duplicateTicket = "Duplicate ticket",
  viewDetails = "View_details",
  knowMore = "know_more",
}
export enum InteractionEventActions {
  click = "click",
  slide = "slide",
  swipe = "swipe",
  impression = "impression",
  close = "close",
  checkDelivery = "check_delivery",
}
export enum InteractionEventPositions {
  defaultPosition = "default",
}
export enum InteractionEventXId {
  default = 'default',
  xId = 'x_id'
}

export enum InteractionEventValues {
  viewMore = "view_more",
  viewLess = "view_less",
  reviewImage = "review_image",
  blogShare = "Click on share icon from blog",
  infoSave = "info_save",
  shopHome = "shop_home",
  beautyQuiz = "beauty_quiz",
  default = 'default',
  ab = 'ab',
  support = "support",
  viewDetails = "View_details",
  knowMore = "know_more",
  reasonForDeletion = "reason for deletion"
}

export enum InteractionTempPageType {
  login = "login",
}

export enum InteractionEventViewType {
  fragment = "fragment",
}
export enum InteractionTargetEntityType {
  item = "item"
}
export enum InteractionFeatureType {
  listingItem = "listing_item",
}
export enum InteractionFeaturePosition {
  default = "default",
}
export enum InteractionEventPageType {
  support = "Support",
  webView = "web_view",
  fDTicketForm = "FD Ticket Form"
}
export enum InteractionEventPageTypeValues {
  default = "default",
}
export enum InteractionEventPageNames {
  myProfile = "My Profile",
  deleteAccount = "Delete Account",
}